import { FormHelperText, FormLabel, Tooltip } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { WindowLocation } from "@reach/router";
import {
  Button,
  Checkbox,
  colors,
  DatePicker,
  Dropdown,
  ffdinPro,
  Field,
  FieldGender,
  Fieldset,
  FieldTitle,
  fontFamily,
  Form,
  Input,
  IUploadZoneFile,
  LittersSubmit,
  Loader,
  PageTitle,
  RadioGroup,
  Textarea,
  UploadZone,
  useButtonProps,
  useInputProps,
  Wrapper,
} from "@royalcanin-fr-loyalty/ui-kit";
import {
  IconEuro16,
  IconInfo16,
} from "@royalcanin-fr-loyalty/ui-kit/src/icons-ts";
import { Tipped } from "@royalcanin-fr-loyalty/ui-kit/src/Tipped";
import { addMonths, endOfDay, format } from "date-fns";
import { isFuture, startOfDay } from "date-fns/esm";
import React, { useEffect } from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import styled from "styled-components";
import url from "url";
import { array, boolean, date, number, object, string } from "yup";
import { Layout } from "../../components/Layout";
import { PetBreedSelector } from "../../components/PetBreedSelector";
import {
  useLitter,
  useLitterArchiveAd,
  useLitterImageGetPostUrls,
  useLitterUpdateAd,
} from "../../lib/hooks/useLitters";
import { navigateTo, useLocationContext } from "../../lib/LocationProvider";
import { cleanEmoji, filterBoolean } from "../../lib/utils";
import { withPage } from "../../lib/withPage";
import Error404 from "../404";

const radioYesNo = [
  {
    label: "Oui",
    value: true,
  },
  {
    label: "Non",
    value: false,
  },
];

const numberInputConfig = {
  parse: (value: any) => parseInt(value, 10) || 0,
  format: (value: any) => `${value}`.slice(0, 4),
};

const PicturesHint = () => (
  <Hint>
    Votre annonce doit comporter au minimum 1 photo (Les annonces comprenant des
    photos sont plus souvent consultées et favorise une vente plus rapide)
  </Hint>
);

const Hint = styled(
  ({
    className,
    children,
  }: {
    className?: string;
    children: React.ReactNode | string;
  }) => (
    <div className={className}>
      <IconInfo16 className="icon" width={16} fill={colors.dark} />
      <div className="title">{children}</div>
    </div>
  ),
)`
  display: flex;

  .icon {
    margin-right: 15px;
  }

  .title {
    ${fontFamily(ffdinPro.medium)}
  }
`;

const LittersAd = ({ location }: { location: WindowLocation<any> }) => {
  const { navigate } = useLocationContext();
  const { litter: litterId, kitCreated } = useLocationContext().query;
  const { litter, loading: litterLoading } = useLitter(litterId);
  // const isFromRoyalStart = (litter && !!litter.royalStartId) || false;
  const { litterUpdateAd } = useLitterUpdateAd({ litterId });
  const { litterArchiveAd } = useLitterArchiveAd({ litterId });
  const {
    litterRequestImageSignUrl,
    loading: litterRequestImageSignUrlLoading,
  } = useLitterImageGetPostUrls({ litterId });

  const title = (
    <PageTitle subTitle="Contenu de votre annonce">
      Publier une annonce
    </PageTitle>
  );

  if (litterLoading || litterRequestImageSignUrlLoading) {
    return (
      <Layout>
        <Wrapper>
          {title}
          <Loader />
        </Wrapper>
      </Layout>
    );
  }

  if (!litterId) {
    return <Error404 location={location} />;
  }

  if (!litter) {
    return <Error404 location={location} />;
  }

  const LofOrLoof = litter.petType === "CAT" ? "LOOF" : "LOF";
  const minPublicationDateEnd = startOfDay(new Date(litter.dob));
  const maxPublicationDateEnd = addMonths(
    endOfDay(new Date(litter.dob)),
    parseInt(process.env.GATSBY_LITTER_MAX_PUBLICATION_DATE_END || "4", 10),
  );

  return (
    <Layout>
      <Wrapper>
        {title}
        <Form
          onSubmit={async ({ images, ...values }) => {
            const usedSignedUrlIndexes: number[] = [];
            const existingImages = images
              .map((image) => image.preview)
              .filter(filterBoolean);

            const imagesInputResolvers = images.map((image, i) => {
              if (image.preview) {
                return {
                  url: image.preview,
                };
              } else {
                const signUrlIndex = litterRequestImageSignUrl.findIndex(
                  (signed, z) => {
                    const value =
                      usedSignedUrlIndexes.indexOf(z) < 0 &&
                      existingImages.reduce<boolean>((r, existing) => {
                        console.log(usedSignedUrlIndexes, z, {
                          pathname: (
                            url.parse(existing)?.pathname || ""
                          ).replace(/^\//, ""),
                          key: signed.key,
                          r,
                        });
                        return (
                          r &&
                          signed.key !==
                            (url.parse(existing)?.pathname || "").replace(
                              /^\//,
                              "",
                            )
                        );
                      }, true);
                    return value;
                  },
                );

                if (signUrlIndex >= 0) {
                  usedSignedUrlIndexes.push(signUrlIndex);

                  return async () => {
                    const file = image.file!;
                    const signed = {
                      url: litterRequestImageSignUrl[signUrlIndex].url,
                      fields: JSON.parse(
                        litterRequestImageSignUrl[signUrlIndex].fields,
                      ),
                    };
                    const formData = new FormData();
                    formData.append("Content-Type", file.type);
                    Object.entries(signed.fields).forEach(([k, v]) => {
                      formData.append(k, v as any);
                    });
                    formData.append("file", file);

                    await fetch(signed.url, {
                      body: formData,
                      method: "POST",
                    });

                    return {
                      index: signUrlIndex,
                    };
                  };
                }

                return;
              }
            });

            const imagesInput = (
              await Promise.all(
                imagesInputResolvers.map(async (resolver) => {
                  if (typeof resolver === "function") {
                    return resolver();
                  } else {
                    return resolver;
                  }
                }),
              )
            ).filter(filterBoolean);

            const res = await litterUpdateAd({
              ...values,
              images: imagesInput,
            });

            if (res && res.result && res.result.id) {
              navigate("/litters/success", {
                litter: res.result.id,
                kitCreated,
                adCreated: true,
              });
            }
          }}
          fieldLabels={{
            shortDesc: "Description",
            publicationDateEnd: "Visible jusqu'au",
            images: "Photos",
            malesAvailable: "Mâles disponibles",
            femalesAvailable: "Femelles disponibles",
            motherId: "ID de la mère",
            fatherId: "ID du père",
            pureBredLitterId: `${LofOrLoof} de la portée`,
            price: "Prix de départ",
            breedId: "Race",
            weeksAvailability: "Délai de disponibilité",
          }}
          initialValues={{
            publicationDateEnd:
              litter.publicationDateEnd || addMonths(new Date(litter.dob), 2),
            shortDesc: litter.shortDesc || "",
            malesAvailable:
              litter.malesAvailable !== null && litter.malesAvailable >= 0
                ? litter.malesAvailable
                : litter.males || 0,
            femalesAvailable:
              litter.femalesAvailable !== null && litter.femalesAvailable >= 0
                ? litter.femalesAvailable
                : litter.females || 0,
            motherId: litter.motherId || "",
            fatherId: litter.fatherId || "",
            isPureBred: litter.isPureBred || false,
            pureBredLitterId: litter.pureBredLitterId || "",
            price: litter.price || 0,
            isSterilized: litter.isSterilized || false,
            isVaccinated: litter.isVaccinated || false,
            isChippedOrTattooed: litter.isChippedOrTattooed || false,
            breedId: (litter.breed && litter.breed.id) || "",
            images: litter.imageUrls.map((preview) => ({
              id: preview,
              preview,
            })) as IUploadZoneFile[],

            royalStartSyncAvailable: litter.royalStartSyncAvailable || false,
            weeksAvailability: litter.weeksAvailability || 8,
          }}
          schema={object().shape({
            publicationDateEnd: date()
              .required()
              .min(
                minPublicationDateEnd,
                () =>
                  `La valeur saisie pour le champ "Visible jusqu'au" ne peut pas être antérieure au ${format(
                    new Date(minPublicationDateEnd),
                    "dd.MM.yyyy",
                  )}`,
              )
              .max(
                maxPublicationDateEnd,
                () =>
                  `La valeur saisie pour le champ "Visible jusqu'au" ne peut pas être postérieure au ${format(
                    new Date(maxPublicationDateEnd),
                    "dd.MM.yyyy",
                  )}`,
              ),
            shortDesc: string().max(2000).required(),
            malesAvailable: number()
              .min(0)
              .max(litter.males + litter.genderUnspecifiedCount)
              .required(),
            femalesAvailable: number()
              .min(0)
              .max(litter.females + litter.genderUnspecifiedCount)
              .required(),
            weeksAvailability: number().min(8).max(12).required(),
            motherId: string().max(40),
            fatherId: string().max(40),
            pureBredLitterId: string().max(30).when("isPureBred", {
              is: true,
              then: string().required(),
            }),
            price: number().min(0).max(9999).required(),
            isSterilized: boolean().required(),
            isVaccinated: boolean().required(),
            isChippedOrTattooed: boolean().required(),
            breedId: string().required(),
            images: array()
              .test(
                "no-heic",
                "Les fichiers de type HEIC/HEIF (format Apple) ne sont pas acceptés",
                (value) =>
                  value.every((file: IUploadZoneFile) => {
                    return !file.file || file.file.type !== "image/heic";
                  }),
              )
              .required("Veuillez télécharger au moins 1 photo"),
          })}
          render={({
            handleSubmit,
            values,
            form: { change, resetFieldState },
          }) => {
            const inputPropsFemalesAvailable = useInputProps({
              name: "femalesAvailable",
              config: numberInputConfig,
            });

            const inputPropsMalesAvailable = useInputProps({
              name: "malesAvailable",
              config: numberInputConfig,
            });

            const inputPropsShortDesc = useInputProps({ name: "shortDesc" });
            const inputPropsImages = useInputProps({ name: "images" });

            return (
              <form onSubmit={handleSubmit}>
                <Row center="md">
                  <Col xs={12} sm={12} md={8} lg={8}>
                    {/* {isFromRoyalStart && (
                    <AdWarning type="warning">
                      Certaines infos provenant de Royal Start ne peuvent être
                      modifiées ici
                    </AdWarning>
                  )} */}
                    <Field>
                      <FieldTitle>Visiblité</FieldTitle>
                      <Row>
                        <Col xs={12} sm={7} md={6} lg={6}>
                          <DatePicker
                            {...useInputProps({ name: "publicationDateEnd" })}
                            format="dd.MM.yyyy"
                            minDate={minPublicationDateEnd}
                            maxDate={maxPublicationDateEnd}
                            openTo="date"
                          />
                          {!isFuture(new Date(values.publicationDateEnd)) && (
                            <FormHelperText
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              error
                            >
                              <ErrorOutline
                                fontSize="inherit"
                                style={{ marginRight: 5 }}
                              />
                              Publication archivée
                            </FormHelperText>
                          )}
                        </Col>
                      </Row>
                    </Field>
                    <Field>
                      <FieldTitle>Description</FieldTitle>
                      <Textarea
                        {...inputPropsShortDesc}
                        onChange={(e) => {
                          const value = e.currentTarget.value;
                          return inputPropsShortDesc.onChange(
                            cleanEmoji(value),
                          );
                        }}
                      >
                        Décrivez en quelques mots la portée ...
                      </Textarea>
                    </Field>
                    <Field>
                      <FieldTitle>Photos</FieldTitle>
                      <PicturesHint />
                      <UploadZone
                        files={values.images}
                        setFiles={(files) => change("images", files)}
                        onDelete={(file) => {
                          change(
                            "images",
                            values.images.filter((f) => f.id !== file.id),
                          );
                        }}
                      />
                      {!!inputPropsImages.helperText && (
                        <FormHelperText error>
                          {inputPropsImages.helperText}
                        </FormHelperText>
                      )}
                    </Field>
                    <Field>
                      <FieldTitle>Disponibilité</FieldTitle>
                      {!!litter.royalStartId && (
                        <Row>
                          <Col xs={12} sm={12} md={12} lg={12}>
                            <div
                              style={{
                                marginBottom: values.royalStartSyncAvailable
                                  ? 0
                                  : 30,
                              }}
                            >
                              <Checkbox
                                {...useInputProps({
                                  name: "royalStartSyncAvailable",
                                  config: {
                                    type: "checkbox",
                                  },
                                })}
                                id="royalStartSyncAvailable"
                              >
                                <CheckboxContentRSsync>
                                  <FormLabel htmlFor="royalStartSyncAvailable">
                                    Mettre à jour automatiquement les
                                    disponibilités de vos animaux selon Royal
                                    Start
                                  </FormLabel>
                                  <Tipped
                                    content="Vos informations sont mises à jours toutes les
                                24 heures maximum. Si vous n'avez plus d'animaux
                                disponibles, votre annonce sera automatiquement
                                archivée."
                                    trigger="mouseenter focus"
                                  >
                                    <IconInfo16
                                      fill={colors.light}
                                      width={16}
                                      height={16}
                                    />
                                  </Tipped>
                                </CheckboxContentRSsync>
                              </Checkbox>
                            </div>
                          </Col>
                        </Row>
                      )}

                      {!litter.royalStartId && !values.royalStartSyncAvailable && (
                        <Row>
                          <Col xs={12} sm={7} md={6} lg={6}>
                            <FieldGender>
                              <Input
                                fullWidth
                                {...inputPropsFemalesAvailable}
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: "0",
                                    max:
                                      litter.females +
                                      litter.genderUnspecifiedCount,
                                  },
                                }}
                              />
                              <span>
                                sur{" "}
                                {litter.females + litter.genderUnspecifiedCount}
                              </span>
                            </FieldGender>
                          </Col>
                          <Col xs={12} sm={7} md={6} lg={6}>
                            <FieldGender>
                              <Input
                                {...inputPropsMalesAvailable}
                                fullWidth
                                type="number"
                                InputProps={{
                                  inputProps: {
                                    min: "0",
                                    max:
                                      litter.males +
                                      litter.genderUnspecifiedCount,
                                  },
                                }}
                              />
                              <span>
                                sur{" "}
                                {litter.males + litter.genderUnspecifiedCount}
                              </span>
                            </FieldGender>
                          </Col>
                        </Row>
                      )}

                      <Row>
                        <Col xs={12} sm={7} md={6} lg={6}>
                          <Fieldset>
                            <Tooltip
                              title="Légalement, vos chiots/chatons ne peuvent pas être vendus avant 8 semaines, mais vous pouvez étendre ce délai jusqu'à 12 semaines."
                              placement="right"
                            >
                              <div>
                                <Dropdown<number>
                                  {...useInputProps({
                                    name: "weeksAvailability",
                                  })}
                                  fullWidth
                                  items={new Array(5).fill(0).map((_, i) => ({
                                    label: `${i + 8} semaines`,
                                    value: i + 8,
                                  }))}
                                />
                              </div>
                            </Tooltip>
                          </Fieldset>
                        </Col>
                      </Row>
                    </Field>
                    {(!litter.breed ||
                      (litter.breed && !litter.breed.name)) && (
                      <Field>
                        <FieldTitle>Race</FieldTitle>
                        <Row>
                          <Col xs={12} sm={7} md={6} lg={6}>
                            <PetBreedSelector
                              petType={
                                (litter && litter.petType.toLowerCase()) || ""
                              }
                            />
                          </Col>
                        </Row>
                      </Field>
                    )}
                    <Field>
                      <FieldTitle>Identification</FieldTitle>
                      <Row>
                        <Col xs={12} sm={7} md={6} lg={6}>
                          <Input
                            fullWidth
                            {...useInputProps({ name: "motherId" })}
                          />
                        </Col>
                        <Col xs={12} sm={7} md={6} lg={6}>
                          <Input
                            fullWidth
                            {...useInputProps({ name: "fatherId" })}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} sm={7} md={6} lg={6}>
                          <Fieldset>
                            <RadioGroup
                              items={radioYesNo}
                              {...useInputProps({
                                name: "isPureBred",
                              })}
                              type="radio"
                            >
                              {LofOrLoof}
                            </RadioGroup>
                          </Fieldset>
                        </Col>
                        <Col xs={12} sm={7} md={6} lg={6}>
                          <Fieldset>
                            <PureBredInput
                              values={values}
                              resetFieldState={resetFieldState}
                              change={change}
                            />
                          </Fieldset>
                        </Col>
                      </Row>
                    </Field>
                    <Field borderless>
                      <FieldTitle>Infos complémentaires</FieldTitle>
                      <Row>
                        <Col xs={12} sm={4} md={5} lg={6}>
                          <Input
                            fullWidth
                            icon={
                              <IconEuro16 fill={colors.darkDk} width={16} />
                            }
                            {...useInputProps({
                              name: "price",
                              config: numberInputConfig,
                            })}
                            type="number"
                          />
                        </Col>
                        <Col xs={12} sm={8} md={7} lg={6}>
                          <Fieldset offset={10}>
                            <RadioGroup
                              items={radioYesNo}
                              {...useInputProps({
                                name: "isSterilized",
                              })}
                              type="radio"
                            >
                              Stérilisation
                            </RadioGroup>
                          </Fieldset>
                          <Fieldset offset={10}>
                            <RadioGroup
                              items={radioYesNo}
                              {...useInputProps({ name: "isVaccinated" })}
                              type="radio"
                            >
                              Vaccination
                            </RadioGroup>
                          </Fieldset>
                          <Fieldset offset={10}>
                            <RadioGroup
                              items={radioYesNo}
                              {...useInputProps({
                                name: "isChippedOrTattooed",
                                // disabled: isFromRoyalStart,
                              })}
                              type="radio"
                            >
                              Puce/Tatouage
                            </RadioGroup>
                          </Fieldset>
                        </Col>
                      </Row>
                    </Field>
                    <LittersSubmit>
                      {litter.publicationDateEnd && (
                        <Button
                          theme="bordered"
                          onClick={async () => {
                            await litterArchiveAd();
                            navigateTo("/litters");
                          }}
                        >
                          Archiver l'annonce
                        </Button>
                      )}
                      <Button type="submit" {...useButtonProps()}>
                        Enregistrer
                      </Button>
                    </LittersSubmit>
                  </Col>
                </Row>
              </form>
            );
          }}
        />
      </Wrapper>
    </Layout>
  );
};

const PureBredInput = ({
  values,
  isFromRoyalStart,
  resetFieldState,
  change,
}: {
  values: any;
  isFromRoyalStart?: boolean;
  resetFieldState: (field: string) => void;
  change: (name: string, value?: any) => void;
}) => {
  const props = useInputProps({
    name: "pureBredLitterId",
  });

  useEffect(() => {
    if (!values.isPureBred && values.pureBredLitterId) {
      change("pureBredLitterId", "");
    }
    resetFieldState("pureBredLitterId");
  }, [values.isPureBred, values.pureBredLitterId]);

  return (
    <Input
      {...props}
      disabled={!values.isPureBred || isFromRoyalStart}
      fullWidth
    />
  );
};

export default withPage(LittersAd, {
  title: "Portées",
});

const CheckboxContentRSsync = styled.div`
  display: flex;
  > div {
    margin-left: 10px;
    height: 16px;
  }
`;
